* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.togger-sidebar {
	position: absolute;
	inset-block-start: 18px;
	z-index: 101;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 40px;
	inset-inline-end: -13px;
	transition: transform 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.25);
	background-color: #ffffff;
	box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.text-white,
.text-white > * {
	color: #fff !important;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.ant-table-row-expand-icon-cell {
	display: none;
}
@media screen and (max-width: 767px) {
	.hide-mobile {
		display: none;
	}
	.ant-table-row-expand-icon-cell {
		display: table-cell;
		padding: 0 !important;
	}
	.ant-layout-content,
	.ant-table-cell {
		padding: 10px !important;
	}
	.ant-layout-content {
		width: 100vw;
	}
	.ant-list-item {
		padding: 8px 10px !important;
	}
	.ant-layout-header {
		padding: 0 20px;
	}
	.mobile-toggler {
		margin-left: -20px !important;
	}
}
